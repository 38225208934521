.birthdayInput {
  background-image: url("gridBg.jpg");
  margin: -1px;
  width: 382px;
  height: 66px;
  background-size: 48px;
  border: none;
  font-family: monospace;
  font-size: 53px;
  padding-left: 9px;
  letter-spacing: 16px;
}

.birthdayTTMMJJJJ {
  width: 382px;
  height: 66px;
  border: none;
  font-family: monospace;
  font-size: 53px;
  padding-left: 9px;
  letter-spacing: 16px;
}

.bordered-box {
  display: flex;
  align-items: center;
  width: 200px;
  height: 76px;
  border: 2px solid lightgray;
  font-family: monospace;
  font-size: 35px;
  padding-left: 1vh;
}
