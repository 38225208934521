.gridText {
  background-image: url("gridBg.jpg");
  margin: -1px;
  width: 690px;
  height: 32px;
  background-size: 23px;
  border: none;
  font-family: monospace;
  font-size: 18px;
  padding-left: 9px;
  letter-spacing: 12px;
}
